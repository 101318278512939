import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

const ArticleCard = ({ node }) => {
  return (
    <AniLink
      className="w-full md:w-1/2 lg:w-1/4 pl-gs pb-15 text-center"
      fade
      to={`/news/${node.uid}`}
    >
      <Fade ssrReveal delay={250} duration={2250}>
        <div>
          {node.data.image.localFile ? (
            <div className="mb-7">
              <Img
                fluid={node.data.image.localFile.childImageSharp.fluid}
                alt={node.data.alt || node.data.title}
              />
            </div>
          ) : (
            <div className="pb-9:7 bg-black opacity-25 mb-7"></div>
          )}
          {node.data.date && (
            <div className="text-xxs mb-1">{node.data.date}</div>
          )}
          {node.data.title && <h3 className="mb-4">{node.data.title}</h3>}
          <span className="uppercase tracking-widest py-1 pl-1 pr-2px border-b font-bold text-sm">
            View
          </span>
        </div>
      </Fade>
    </AniLink>
  )
}

export default ArticleCard
