import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import ArticleCard from '../components/ArticleCard'

const IndexPage = ({ location, data }) => {
  const page = data.prismicHome.data
  const articles = data.allPrismicNewsArticle.edges

  return (
    <Layout
      seoTitle={page.meta_title || page.title}
      seoDescription={page.meta_description}
      location={location}
    >
      <div className="bg-charcoal min-h-screen relative z-0">
        <Fade ssrReveal delay={250} duration={2250}>
          <div className="absolute lg:pt-hh inset-x-0 w-full h-full overflow-hidden z-10">
            {page.hero_image.localFile && (
              <Img
                loading="eager"
                className="hidden md:block h-full"
                fluid={page.hero_image.localFile.childImageSharp.fluid}
                alt={page.hero_image.alt || page.hero_lead.html}
              />
            )}
            {page.mobile_hero_image.localFile && (
              <Img
                loading="eager"
                className="md:hidden h-full"
                fluid={page.mobile_hero_image.localFile.childImageSharp.fluid}
                alt={page.mobile_hero_image.alt || page.hero_lead.html}
              />
            )}
          </div>
        </Fade>
        <Fade ssrReveal delay={450} duration={2250} bottom distance={'25px'}>
          <div className="relative min-h-screen flex items-end lg:items-center pt-hh px-ogs z-20">
            <div className="wrapper pb-15 lg:py-15">
              {page.hero_heading && (
                <h1 className="text-2xl xl:text-3xl font-condensed uppercase mb-4 tracking-wider">
                  {page.hero_heading.map((item, index) => {
                    return (
                      <span key={index} className="block">
                        {item.hero_heading_row}
                      </span>
                    )
                  })}
                </h1>
              )}
              {page.hero_lead.html && (
                <div
                  className="mb-12 max-w-sm"
                  dangerouslySetInnerHTML={{ __html: page.hero_lead.html }}
                />
              )}
              {page.hero_link.url && page.hero_link_text && (
                <AniLink className="button" fade to={page.hero_link.url}>
                  {page.hero_link_text}
                </AniLink>
              )}
            </div>
          </div>
        </Fade>
      </div>

      <div className="bg-white text-black px-ogs pt-24 pb-15 lg:py-40 xl:py-56">
        <div className="wrapper">
          <div className="flex flex-col lg:flex-row-reverse lg:-ml-gs text-center">
            <Fade
              ssrReveal
              delay={450}
              duration={2250}
              bottom
              distance={'25px'}
            >
              <div className="lg:w-1/2 lg:pl-gs mb-20 lg:mb-0 flex flex-col justify-center">
                {page.cta_heading && (
                  <h2 className="text-xl lg:text-2xl font-condensed uppercase mb-10 tracking-wider max-w-md mx-auto">
                    {page.cta_heading}
                  </h2>
                )}
                {page.cta_link.url && page.cta_link_text && (
                  <AniLink
                    className="button mx-auto bg-black text-white"
                    fade
                    to={page.cta_link.url}
                  >
                    {page.cta_link_text}
                  </AniLink>
                )}
              </div>
            </Fade>
            <Fade ssrReveal delay={250} duration={2250}>
              {page.cta_image.localFile && (
                <div className="lg:w-1/2 lg:pl-gs">
                  <div className="max-w-sm mx-auto md:max-w-md lg:max-w-lg">
                    <Img
                      fluid={page.cta_image.localFile.childImageSharp.fluid}
                      alt={page.cta_image.alt || page.cta_heading}
                    />
                  </div>
                </div>
              )}
            </Fade>
          </div>
        </div>
      </div>

      {articles.length > 0 && (
        <div className="px-ogs">
          <div className="wrapper py-15 lg:py-30 text-center">
            <Fade ssrReveal delay={250} duration={2250}>
              <h3 className="uppercase mb-15 lg:mb-30 tracking-wider text-xl lg:text-2xl font-condensed">
                Latest News
              </h3>
              <div className="flex flex-wrap -ml-gs -mb-15 pb-15 lg:pb-20">
                {articles.map((item, index) => {
                  return <ArticleCard node={item.node} key={index} />
                })}
              </div>
              <AniLink className="button" fade to="/news">
                All News
              </AniLink>
            </Fade>
          </div>
        </div>
      )}
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    prismicHome {
      data {
        cta_heading
        cta_image {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        cta_link_text
        cta_link {
          url
        }
        hero_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          alt
        }
        mobile_hero_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          alt
        }
        hero_heading {
          hero_heading_row
        }
        hero_lead {
          html
        }
        hero_link {
          url
        }
        hero_link_text
        title
        meta_title
        meta_description
      }
    }
    allPrismicNewsArticle(
      sort: { order: DESC, fields: data___date }
      limit: 4
    ) {
      edges {
        node {
          data {
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, maxHeight: 900, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            title
            date(formatString: "MMMM Do, YYYY")
          }
          uid
          url
        }
      }
    }
  }
`
